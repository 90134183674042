//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls two endpoint, and also handles the end user details input and
// updates the update-packages with the end users personal details, as well as
// creating a booking. Until the details are completely filled, the end user
// cannot move forward
//
// POST /v1/portal/create-booking
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { useNavigate } from "react-router-dom";
import {
  getFirstNameAction,
  getAddressAction,
  getEmailAction,
  getLastNameAction,
  updatePackagesAction,
  getPhoneNumberAction,
  getPackagesAction,
  confirmBookingAction,
} from "../State/ActionCreators/index";
import { PageTitle, Button, Hero, Error, ButtonLoading, FormControl } from "../components/index";
import { useParams } from "react-router";
import { CustomBtnWidth, DesktopWrapper, WhiteBackground } from "../Utils/styles";
import { formatPrice } from "../Utils/helper";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface products {
  cost: number;
  currency: string;
  facts: any;
  guis: guis[];
  latest_cancel: string;
  product_id: string;
  cost_net: string;
}

interface categories {
  en: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id: string;
  product_groups: product_groups[];
  categories: categories[];
  facts: any;
  guis: guis[];
}

interface createNewObjectProps {
  packages: {
    package_id: string;
    product_groups: product_groups[];
    categories: categories[];
    facts: any;
    guis: guis[];
  }[];
  combine_packages: boolean;
}

const cookiefacts = JSON.parse(localStorage.getItem("cookiefacts") as string);
const referer = cookiefacts?.facts?.referer;
const renderMini = referer === "mini";
const renderVw = referer === "volkswagen";
const renderManx = referer === "manx";
const renderEtravel = referer === "etravel_mercedes";

const renderPageTitleOnSummaryScreen = !getPartnerConfiguration(referer).SHOW_PAGE_TITLE;

const SummaryInformationScreen = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const skipConfirmation = !getPartnerConfiguration(referer).USE_CONFIRMATION_PAGE;

  /* Destructuring the useParams hook from react-router-dom and assigning it to a variable called
packageIdParams. */
  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Using the useSelector hook to get the updated packages from the Redux store. */
  const getUpdatedPackages = useSelector((state: RootState) => {
    return state.getUpdatedPackages;
  });

  const {
    loading: loadingUpdatedPackages,
    error: errorUpdatedPackages,
  }: {
    loading: boolean;
    error: boolean;
  } = getUpdatedPackages;

  /* Checking if there is a localStorage item called "packages" and if there is, it is parsing it into an
array of packageProps. If there is no localStorage item called "packages", it is creating an empty
array. */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");

  /* Destructuring the getCreateBooking object and assigning the success and error properties to the
 successBooking and errorBooking variables. */
  const getCreateBooking = useSelector((state: RootState) => {
    return state.getCreateBooking;
  });

  const {
    success: successBooking,
    error: errorBooking,
    bookingId: booking_id,
  }: { success: boolean; error: boolean; loading: boolean; bookingId: string } = getCreateBooking;

  /* Destructuring the getConfirmBooking object and assigning the error property to a new variable
  called errorConfirmingBooking. */
  const getConfirmBooking = useSelector((state: RootState) => {
    return state.getConfirmBooking;
  });

  const { error: errorConfirmingBooking }: { error: boolean } = getConfirmBooking;

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  /* Destructuring the state from the redux store. */
  const getInformationDetails = useSelector((state: RootState) => {
    return state.getInformationDetails;
  });

  const {
    customerFirstName,
    customerLastName,
    customerAddress: {
      streetAndNo: customerStreet,
      postalCode: customerPostal,
      city: customerCity,
      country: customerCountry,
    },
    customerEmail,
    phone_number,
    customerConfirmed,
  } = getInformationDetails;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* state level for inputs */
  const [firstName, setFirstName] = useState<string>(customerFirstName);
  const [lastName, setLastName] = useState<string>(customerLastName);
  const [streetAndNo, setStreetAndNo] = useState<string>(customerStreet);
  const [postalCode, setPostalCode] = useState<string>(customerPostal);
  const [city, setCity] = useState<string>(customerCity);
  // eslint-disable-next-line
  const [country, setCountry] = useState<string>(customerCountry);
  const [cstEmail, setEmail] = useState<string>(customerEmail);
  const [phoneNumber, setPhoneNumber] = useState<any>(phone_number);
  const [cstConfirmed, setCstConfirmed] = useState<boolean>(customerConfirmed);

  const [packageState, setPackageState] = useState<any>(() => {
    /* Finding the package that matches the packageIdParams. */
    const singlePackage = packages.find((item) => item.package_id === packageIdParams);
    if (singlePackage) {
      /* Mapping over the product_groups array and returning a new array with the same structure. */
      const product_groups = singlePackage?.product_groups?.map((pg: product_groups) => {
        const { deletable, multiple } = pg;
        const products = pg?.products?.map((product: products) => {
          const { facts } = product;

          return {
            ...product,
            facts: {
              ...facts,
            },
          };
        });
        return { deletable, multiple, products };
      });

      /* Destructuring the facts object from the singlePackage object. */
      const { facts } = singlePackage;

      /* Creating a new object with the same properties as the singlePackage object, but with the
      package_id property set to the value of the packageIdParams variable. */
      return {
        ...singlePackage,
        package_id: packageIdParams,
        product_groups,
        facts: {
          ...facts,
          first_name: firstName,
          last_name: lastName,
          email: cstEmail,
          phone: phoneNumber,
          home_city: city,
          home_post_code: postalCode,
          home_street: streetAndNo,
        },
      };
    } else {
      return {};
    }
  });

  useEffect(() => {
    /* Updating the state of the package. */
    setPackageState((oldState: any) => ({
      ...oldState,
      facts: {
        ...oldState.facts,
        first_name: firstName,
        last_name: lastName,
        email: cstEmail,
        phone: phoneNumber,
        home_city: city,
        home_post_code: postalCode,
        home_street: streetAndNo,
        // pickup_country: country,
      },
    }));
  }, [
    city,
    cstEmail,
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    streetAndNo,
    // country,
  ]);

  /* Creating an object with a key of package and a value of packageState. */
  const createBookingObject = { packages: [packageState] };

  /* Creating a new variable called bookingObject and assigning it the value of the createBookingObject
function. */
  const bookingObject = createBookingObject;

  const createNewResponseObject = (packages: createNewObjectProps, agreeTerms: string) => {
    let PackageIndex: any;

    /* Finding the index of the package in the packages array. */
    packages.packages?.forEach((singlePackage: packageProps, PKIndex: number) => {
      if (singlePackage?.package_id === packageIdParams) {
        PackageIndex = PKIndex;
      }
    });

    // make a copy of the old world - the old packages
    let manipulatedPackages = { ...packages };

    // manipulate the facts of the produt of the product group
    manipulatedPackages.packages[PackageIndex].facts.agreetc = agreeTerms;

    // return the object
    return manipulatedPackages;
  };

  useEffect(() => {
    /* Dispatching the action to the reducer. */
    dispatch(getFirstNameAction(firstName));
    dispatch(getLastNameAction(lastName));
    dispatch(getAddressAction({ streetAndNo, postalCode, city, country }));
    dispatch(getEmailAction(cstEmail));
    dispatch(getPhoneNumberAction(phoneNumber));
    // eslint-disable-next-line
  }, [dispatch, firstName, lastName, cstEmail, streetAndNo, postalCode, city, country, phoneNumber]);

  /* Destructuring the state object. */
  const getRegisterPay = useSelector((state: RootState) => {
    return state.getRegisterPayer;
  });

  let {
    error: errorRegisterPay,
  }: {
    error: boolean;
  } = getRegisterPay;

  const [autoUpdateLoading, setAutoUpdateLoading] = useState<boolean>();

  useEffect(() => {
    /**
     * gets the error count in the storage
     */
    const errCount = JSON.parse(localStorage.getItem("err") || "null");
    /**
     * if there is an update error, the select and update process
     * is automatically triggered
     */
    const handleErrorOnUpdatePackages = async () => {
      Object.keys(facts).length > 0 &&
        setTimeout(() => {
          dispatch(
            getPackagesAction({
              facts,
            })
          );

          const readStore = JSON.parse(localStorage.getItem("readStore") || "[]");

          setTimeout(() => {
            if (Object.keys(facts).length > 0) {
              dispatch(
                updatePackagesAction({
                  packages: [...readStore],
                  combine_packages: true,
                })
              );
            }
            setPackageState(readStore[0]);
          }, 500);
        }, 500);
    };

    /**
     * triggers the function if the
     * errorCount is less than 3 and error is true
     */
    if (errorUpdatedPackages && errCount < 3) {
      setAutoUpdateLoading(true);
      handleErrorOnUpdatePackages();
    } else if (!errorUpdatedPackages) {
      setAutoUpdateLoading(false);
    }
  }, [errorUpdatedPackages, dispatch, facts, autoUpdateLoading]);

  const calculateCosts = (product_groups: product_groups[]) => {
    let productsSum = 0;

    product_groups &&
      // eslint-disable-next-line
      product_groups?.map((pg: product_groups) => {
        let productCostsSum = pg?.products?.map((product: products) => {
          if (product?.facts?.enabled === "1") {
            return product?.cost;
          } else if (product?.facts?.enabled === "0") {
            return 0;
          } else return "";
        });

        let value = 0;

        const sumsReduced: number = productCostsSum.reduce((pV: number, cV: any) => pV + cV, value);

        productsSum += sumsReduced;
      });

    return productsSum;
  };

  let myBookingObject = {
    booking_id: booking_id,
  };

  const paymentPage = (packageId: string | undefined, skipPayment: boolean, skipConfirmation: boolean) => {
    const agree = packageState?.facts?.agreetc;

    if (
      agree === "1" &&
      customerFirstName &&
      customerLastName &&
      postalCode &&
      streetAndNo &&
      city &&
      cstEmail &&
      phoneNumber !== undefined
    ) {
      if (!errorBooking && !errorUpdatedPackages && successBooking && cstConfirmed) {
        if (skipPayment && skipConfirmation) {
          // skip payment && confirmation page;
          // confirm the booking right here
          dispatch(confirmBookingAction(myBookingObject));
          setTimeout(() => {
            if (!errorConfirmingBooking) {
              navigate(`/experience/${facts?.referer}/packages/${packageIdParams}/final-overview`);
            }
          }, 1000);
        } else if (skipPayment) {
          navigate(`/experience/${facts?.referer}/packages/${packageId}/confirmation`);
        } else {
          navigate(`/experience/${facts?.referer}/packages/${packageId}/payment-gateway`);
        }
      }
    } else {
      alert("fill all details & accept condition");
    }
  };

  /**
   * If the language is English and the loadingRegisterPay is false, return the string "Checkout"
   * @returns The buttonState function is being returned.
   */
  const buttonState_ = () => {
    if (englishLanguage && !loadingUpdatedPackages) return "Apply changes";

    if (!englishLanguage && !loadingUpdatedPackages) return "Weiter";

    if (loadingUpdatedPackages) return <ButtonLoading />;

    return "Continue";
  };
  const buttonState = buttonState_();

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <Hero
            defaultBackdropAction={false}
            cityBackdropAction={true}
            showCloseBar={true}
            navigationRoute={() => navigate(-1)}
            showLanguageBar={false}
            vehicle={facts.vehicle}
            business_area={facts.business_area}
            isFinalOverviewPage={false}
            isLandingPage={false}
            referer={facts.referer}
            isSummaryPage={true}
            facts={facts}
          />

          {errorUpdatedPackages ? (
            <>
              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                headerText={
                  englishLanguage
                    ? "Sorry, a server error occured updating your package"
                    : "Entschuldigung, es scheint ein Problem zu geben."
                }
                bodyText={
                  englishLanguage
                    ? "Please go back and try again"
                    : "Bitte gehen Sie zurück und versuchen Sie es erneut"
                }
              />
              <CustomBtnWidth>
                <Button title="Back Home" onClick={() => navigate(-1)} />
              </CustomBtnWidth>
            </>
          ) : errorBooking ? (
            <>
              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_2.png`}
                headerText={
                  englishLanguage
                    ? "An error occured creating your booking"
                    : "Leider ist ein Fehler bei der Bestätigung Ihrer Buchung aufgetreten"
                }
                bodyText={
                  englishLanguage
                    ? "Please go back and try again"
                    : "Bitte gehen Sie zurück und versuchen Sie es erneut"
                }
              />
              <CustomBtnWidth>
                <Button
                  title="Go Back"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </CustomBtnWidth>
            </>
          ) : (
            packages &&
            packages?.map((singlePackage: packageProps) => {
              if (singlePackage?.package_id === packageIdParams) {
                const totalCost = calculateCosts(singlePackage?.product_groups);
                const skipPayment = totalCost === 0;
                return (
                  <section key={singlePackage?.package_id} style={{ background: "var(--summary-bg)" }}>
                    {renderVw && renderManx && (
                      <div className="section-center">
                        <h5 style={{ padding: "12px 0" }}>
                          Have a final look at your orders and provide some final booking data before you proceed to the
                          payment.
                        </h5>
                      </div>
                    )}

                    {!renderPageTitleOnSummaryScreen &&
                      (englishLanguage ? (
                        <PageTitle
                          activityLowerCase="Your shopping cart"
                          title={
                            "Have a final look at your orders and provide some final booking data before you proceed to the payment."
                          }
                        />
                      ) : (
                        <PageTitle
                          activityLowerCase={"ZUSAMMENFASSUNG UND FINALE INFORMATIONEN"}
                          title={
                            "Schau Dir die Bestellungen noch einmal an und ergänze letzte Buchungsdaten, bevor Du zur Zahlung übergehst."
                          }
                        />
                      ))}

                    {/* HARD CODED SHARENOW SUMMARY */}
                    {/* ************************** */}
                    <div className="desktop-view">
                      <div className="desktop-view-products">
                        {/* HARD CODED SHARENOW SUMMARY */}
                        {facts?.flow === "0" &&
                          (facts?.referer === "sharenow" ||
                            facts?.referer === "sandbox" ||
                            facts?.referer === "ces24") && (
                            <PackageDetails key={Math.random().toString(36).slice(8)}>
                              <section>
                                <div className="imageAndInfo">
                                  <div className="image-container">
                                    <img
                                      src={`${envStaticHost}/static/${facts?.referer}/car/booking.png`}
                                      alt=""
                                      className="image"
                                    />
                                  </div>

                                  <div className="imageAndInfo__content">
                                    <h4
                                      style={{
                                        margin: "0",
                                        textTransform: "capitalize",
                                        letterSpacing: "0",
                                      }}
                                    >
                                      SHARE NOW
                                    </h4>
                                    <p>Book after checkout</p>
                                  </div>
                                </div>

                                <div className="infoPrice info-price-hide">
                                  <img
                                    src={`${envStaticHost}/static/${facts?.referer}/car/logo.svg`}
                                    alt="sharenow"
                                    style={{ width: "1.5rem" }}
                                  />
                                </div>
                              </section>
                            </PackageDetails>
                          )}
                        {/* HARD CODED SHARENOW SUMMARY */}

                        {/* ************************** */}
                        {singlePackage?.product_groups?.map((product_group: product_groups) =>
                          product_group?.products?.map(
                            (singleProduct: products) =>
                              singleProduct?.facts?.enabled === "1" &&
                              singleProduct?.guis?.map(
                                (guis: guis, idx: number) =>
                                  idx === 0 && (
                                    <PackageDetails key={Math.random().toString(36).slice(8)}>
                                      <section>
                                        <div className="imageAndInfo">
                                          <div className="image-container">
                                            <img src={guis?.media?.[0]} alt="" className="image" />
                                          </div>
                                          <div className="imageAndInfo__content">
                                            <h2
                                              style={{
                                                margin: "0",
                                                textTransform: "capitalize",
                                                letterSpacing: "0",
                                                color: "var(--clr-summary-top)",
                                              }}
                                            >
                                              {singleProduct?.facts?.vendor_name}
                                            </h2>
                                            <p
                                              style={{ color: "(var(--clr-summary-primary-1)", fontWeight: "bold" }}
                                              dangerouslySetInnerHTML={{
                                                __html: (englishLanguage ? guis?.title?.en : guis?.title?.de)
                                                  ?.replace("<br/>", " ")
                                                  ?.replace("<br>", " "),
                                              }}
                                            />
                                            <p style={{ color: "var(--clr-summary-primary-1)" }}>
                                              {singleProduct?.facts?.count}{" "}
                                              {englishLanguage
                                                ? singleProduct?.facts?.count === "1"
                                                  ? "Adult"
                                                  : "Adults"
                                                : singleProduct?.facts?.count === "1"
                                                ? "Erwachsene"
                                                : "Erwachsene"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="infoPrice info-price-hide">
                                          <h4 style={{ color: "var(--clr-summary-top)" }}>
                                            {formatPrice(singleProduct?.cost, englishLanguage)}{" "}
                                          </h4>
                                        </div>
                                      </section>
                                    </PackageDetails>
                                  )
                              )
                          )
                        )}

                        <React.Fragment>
                          <PackageDetails>
                            <hr />
                            <section className="totalSection">
                              <div className="imageAndInfo">
                                {/* <div className="total-no-image"></div> */}
                                <div>
                                  <h5 style={{ color: "(var(--clr-summary-primary-1)" }}>
                                    {englishLanguage ? " Total (incl. VAT)" : "Gesamtsumme (inkl. MwSt.)"}
                                  </h5>
                                </div>
                              </div>{" "}
                              <div className="infoPrice ">
                                {/* var(--input-element-border-bottom) */}
                                <h4>{formatPrice(totalCost, englishLanguage)}</h4>
                              </div>
                            </section>
                          </PackageDetails>
                        </React.Fragment>
                      </div>

                      <FormControl
                        englishLanguage={englishLanguage}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        streetAndNo={streetAndNo}
                        setStreetAndNo={setStreetAndNo}
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        city={city}
                        setCity={setCity}
                        cstEmail={cstEmail}
                        setEmail={setEmail}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        cstConfirmed={cstConfirmed}
                        setCstConfirmed={setCstConfirmed}
                        singlePackage={singlePackage}
                        dispatch={dispatch}
                        updatePackagesAction={updatePackagesAction}
                        createNewResponseObject={createNewResponseObject}
                        packageState={packageState}
                        setPackageState={setPackageState}
                        errorUpdatedPackages={errorUpdatedPackages}
                        bookingObject={bookingObject}
                        skipPayment={skipPayment}
                      />
                    </div>

                    <CustomBtnWidth>
                      <Button
                        title={buttonState}
                        onClick={() => paymentPage(packageIdParams, skipPayment, skipConfirmation)}
                        inactive={
                          singlePackage?.facts?.agreetc === "1" &&
                          customerFirstName &&
                          customerLastName &&
                          postalCode &&
                          streetAndNo &&
                          city &&
                          cstEmail &&
                          phoneNumber !== undefined &&
                          cstConfirmed &&
                          successBooking
                        }
                      />
                    </CustomBtnWidth>
                  </section>
                );
              } else return null;
            })
          )}
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const Wrapper = styled.div`
  z-index: 11;
  position: relative;

  .information-container {
    margin: 1rem 1rem;
  }

  .radioDiv {
    margin: 0 1rem;
    display: flex;
    justify-content: left;
    align-items: center;

    .inputRadio {
      margin: 0 1rem 0 0;
    }
  }

  .wrapper {
    position: relative;
    margin: 1.5rem 0;
    width: 100%;

    .label {
      padding: 0;
      cursor: text;
      color: #677687;
      font-family: var(--p-bold);
      background-color: var(--clr-white);

      h5 {
        color: var(--clr-summary-primary-1);
      }
    }

    .InputElement {
      margin: 0.5rem 0;
    }

    .input:focus ~ .label,
    .input:not(:placeholder-shown).input:not(:focus) ~ .label {
      top: 0;
      left: 0.8rem;
      font-size: 0.8rem;
      color: var(--clr-primary-1);
    }
  }

  textarea {
    background-color: var(--clr-white);
  }

  @media screen and (min-width: 800px) {
    .desktop-view {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 50vw;
      margin: 0 auto;
      flex-direction: row-reverse;

      .desktop-view-products {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 45%;
      }

      .information-container {
        width: 50%;
        margin: 1rem 0;

        .details-heading {
          margin-bottom: 32px;
        }
      }

      .radioDiv {
        margin: 1rem 0;
      }
    }

    hr {
      display: none;
    }
  }
`;

const PackageDetails = styled.div`
  margin: 1rem 1rem;
  position: relative;

  hr {
    background: var(--hr-);
    height: 2px;
    margin: 0.5rem 0 0.5rem 4.5rem;
  }

  .totalSection {
    height: 2rem;
    margin-top: 1.5rem;
  }

  /* .total-no-image {
    width: 4rem;
    height: 2rem;
    margin-right: 0.5rem;
    background: var(--clr-summary-bg);
  } */

  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 5rem;

    .imageAndInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* width: 60%; */

      h5 {
        color: var(--clr-primary-3);
      }

      &__content {
        h4 {
          color: var(--clr-primary-1);
        }

        p {
          line-height: 20px;

          &:first-of-type {
            /* font-family: "AsteriskSansPro Bold"; */
          }
        }
      }
    }

    .image-container {
      width: 4rem;
      height: 4rem;
      margin-right: 14px;

      .details-heading {
        margin: 1rem 0;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1.5px solid var(--clr-primary-2);
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .infoPrice {
      display: flex;
      align-items: top;
    }
  }

  @media screen and (min-width: 800px) {
    /* width: 50vw;
    margin: 2rem auto; */

    margin: 20px 0;
    width: -webkit-fill-available;

    .totalSection {
      margin: 15px auto 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total-no-image {
      display: none;
    }

    section {
      margin: 0;
      height: auto;

      .imageAndInfo {
        width: 100%;
        justify-content: flex-start;

        & > div {
          flex: 1;

          &:first-child {
            flex: 0 0 3rem;
          }
        }

        &__content {
          p {
            line-height: 18px;
          }
        }

        .imageAndInfo__content {
          h2 {
            text-transform: capitalize;
            letter-spacing: 0;
            color: var(--clr-primary-1);
            margin: 0;
          }
        }
      }

      .image-container {
        width: 3rem;
        height: 3rem;

        .details-heading {
          margin: 0;
        }
      }

      .infoPrice {
      }

      .info-price-hide {
        display: none;
      }
    }
  }
`;

export default SummaryInformationScreen;
